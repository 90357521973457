import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useIdleTimer } from 'react-idle-timer';
import { useCookies } from 'react-cookie';
import { getCookie } from 'common/helpers/Cookies';
import { STORAGE_KEYS } from 'api/redux/constants/StorageKeys';
import { ERoutes } from 'ERoutes';

export const InactivityWrapper: React.FC = () => {
	const { logout, user } = useAuth0();
	const tenMins = 600000;

	const [, , removeCookies] = useCookies([
		STORAGE_KEYS.FUND.COOKIE,
		STORAGE_KEYS.SPONSOR.COOKIE,
		STORAGE_KEYS.INVESTOR.COOKIE,
		STORAGE_KEYS.SUBDOMAIN.COOKIE,
	]);

	const inactiveLogout = () => {
		if (user) {
			const url = new URL(window.location.origin);
			const subdomain = getCookie(STORAGE_KEYS.SUBDOMAIN.COOKIE);
			if (subdomain) {
				url.searchParams.append(STORAGE_KEYS.SUBDOMAIN.COOKIE, subdomain);
			}

			logout({ logoutParams: { returnTo: url.toString() } });
			removeCookies(STORAGE_KEYS.FUND.COOKIE, { path: ERoutes.Dashboard });
			removeCookies(STORAGE_KEYS.SPONSOR.COOKIE, { path: ERoutes.Dashboard });
			removeCookies(STORAGE_KEYS.INVESTOR.COOKIE, { path: ERoutes.Dashboard });
			removeCookies(STORAGE_KEYS.SUBDOMAIN.COOKIE, { path: ERoutes.Dashboard });
		}
	};

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const { getRemainingTime, getLastActiveTime } = useIdleTimer({
		timeout: tenMins,
		onIdle: inactiveLogout,
		debounce: 500,
		crossTab: true,
	});

	return <></>;
};

export default InactivityWrapper;
