import { useState, useEffect, SetStateAction, FC } from 'react';
import { Grid, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import numberFormat from 'common/helpers/Math';
import { selectDropdown } from 'api/redux/DropdownReducer';
import { selectSponsorPrimaryColor } from 'api/redux/siteColorReducer';
import { useSelector } from 'react-redux';
import { IActivitySummaryProps } from './DashboardTypes';
import { ICapitalActivityData } from 'modules/reports/CapitalAccountStatement/capital-activity.types';
import { CASValues, ICASSummaryProps } from './types';
import {
	ActivitySummaryBox,
	CapitalActivityContent,
	CapitalActivityHeader,
	CapitalActivitySubHeader,
	HeaderContainer,
	PeriodEndingText,
	SummaryCard,
	SummaryGrid,
	ViewMoreDetailsContainer,
	ViewMoreDetailsIcon,
	ViewMoreDetailsItems,
	ViewMoreDetailsText,
} from './styles';

export const ActivitySummary: FC<IActivitySummaryProps> = ({
	capitalActivityData,
}) => {
	const grants = useSelector(selectDropdown);
	const { currentInvestor, currentFund, currentSponsor } = grants.grants;
	const [netUnrealized, setNetUnrealized] = useState<number>(0);
	const [operatingCosts, setOperatingCosts] = useState<number>(0);
	const [realizedCosts, setRealizedCosts] = useState<number>(0);
	const [capitalValue, setCapitalValue] = useState<number>(0);
	const [reportingPeriod, setReportingPeriod] = useState<string>('');

	const setCASValue = (
		attributeData: ICapitalActivityData[],
		casValue: CASValues,
		setData: (value: SetStateAction<number>) => void,
		checkYTD = true,
	) => {
		const attrData: ICapitalActivityData[] = attributeData.filter(
			(attribute) =>
				attribute.name.includes(casValue) &&
				(!checkYTD || (checkYTD && attribute.name.includes('(YTD)'))) &&
				attribute.value !== '',
		);

		const value: number = attrData
			.map((entry) => parseInt(entry.value))
			.reduce((prev, next) => prev + next, 0);

		setData(value);
	};

	const printValue = (value: number): string => {
		return Math.abs(value) !== 0 ? numberFormat(value) : '-';
	};

	useEffect(() => {
		if (
			currentInvestor.id === 0 ||
			currentFund.id === 0 ||
			currentSponsor.id === 0 ||
			!capitalActivityData
		) {
			setNetUnrealized(0);
			setOperatingCosts(0);
			setRealizedCosts(0);
			setCapitalValue(0);
		}

		if (!capitalActivityData) return;

		setReportingPeriod(capitalActivityData.reportingPeriod);
		setCASValue(
			capitalActivityData.attributeData,
			CASValues.NetOperating,
			setOperatingCosts,
		);
		setCASValue(
			capitalActivityData.attributeData,
			CASValues.RealizedInvestmentGain,
			setRealizedCosts,
		);
		setCASValue(
			capitalActivityData.attributeData,
			CASValues.UnrealizedInvestmentGain,
			setNetUnrealized,
		);
		setCASValue(
			capitalActivityData.attributeData,
			CASValues.ClosingCapitalAccount,
			setCapitalValue,
			false,
		);
	}, [capitalActivityData, currentFund.id, currentInvestor.id, currentSponsor]);

	const ViewFullStatement: FC = () => {
		const sponsorColor = useSelector(selectSponsorPrimaryColor);

		return (
			<ViewMoreDetailsContainer>
				<Link to="/reports">
					<ViewMoreDetailsItems>
						<ViewMoreDetailsIcon sx={{ color: `${sponsorColor}` }} />
						<ViewMoreDetailsText
							align="center"
							variant="h6"
							style={{ color: `${sponsorColor}`, padding: 5 }}
						>
							View Capital Account Statements
						</ViewMoreDetailsText>
					</ViewMoreDetailsItems>
				</Link>
			</ViewMoreDetailsContainer>
		);
	};

	const NetOperatingIncomeLabel: FC = () => {
		return (
			<>
				<CapitalActivityHeader align="center">
					Net Operating
				</CapitalActivityHeader>
				<CapitalActivitySubHeader align="center">
					Income
				</CapitalActivitySubHeader>
			</>
		);
	};

	const NetUnrealizedGainLabel: FC = () => {
		return (
			<>
				<CapitalActivityHeader align="center">
					Net Unrealized
				</CapitalActivityHeader>
				<CapitalActivitySubHeader align="center">
					Gain/Loss
				</CapitalActivitySubHeader>
			</>
		);
	};

	const NetRealizedInvestmentLabel: FC = () => {
		return (
			<>
				<CapitalActivityHeader align="center">
					Net Realized
				</CapitalActivityHeader>
				<CapitalActivitySubHeader align="center">
					Gain/Loss
				</CapitalActivitySubHeader>
			</>
		);
	};

	const CapitalAccountValueLabel: FC = () => {
		return (
			<>
				<CapitalActivityHeader align="center">
					Capital Account
				</CapitalActivityHeader>
				<CapitalActivitySubHeader align="center">
					Value
				</CapitalActivitySubHeader>
			</>
		);
	};

	const CASSummaryCard: FC<ICASSummaryProps> = ({ value, children }) => {
		const sponsorColor = useSelector(selectSponsorPrimaryColor);

		return (
			<SummaryCard elevation={0}>
				{children}
				<CapitalActivityContent
					style={{ color: `${sponsorColor}` }}
					align="center"
					variant="h6"
				>
					{printValue(value)}
				</CapitalActivityContent>
			</SummaryCard>
		);
	};

	const Title: FC = () => {
		return (
			<HeaderContainer>
				<Grid container direction={'row'}>
					<Grid item xs={6} md={6}>
						<Typography variant="h4">Capital Account Summary</Typography>
					</Grid>
					<Grid item xs={6} md={6}>
						<ViewFullStatement />
					</Grid>
				</Grid>
				<PeriodEndingText>
					{capitalActivityData && reportingPeriod !== ''
						? `For the ${reportingPeriod}`
						: '-'}
				</PeriodEndingText>
			</HeaderContainer>
		);
	};

	const SummaryCards: FC = () => {
		return (
			<SummaryGrid container>
				<Grid item xs={3}>
					<CASSummaryCard value={operatingCosts}>
						<NetOperatingIncomeLabel />
					</CASSummaryCard>
				</Grid>
				<Grid item xs={3}>
					<CASSummaryCard value={netUnrealized}>
						<NetUnrealizedGainLabel />
					</CASSummaryCard>
				</Grid>
				<Grid item xs={3}>
					<CASSummaryCard value={realizedCosts}>
						<NetRealizedInvestmentLabel />
					</CASSummaryCard>
				</Grid>
				<Grid item xs={3}>
					<CASSummaryCard value={capitalValue}>
						<CapitalAccountValueLabel />
					</CASSummaryCard>
				</Grid>
			</SummaryGrid>
		);
	};

	return (
		<ActivitySummaryBox>
			<Title />
			<SummaryCards />
		</ActivitySummaryBox>
	);
};

export default ActivitySummary;
