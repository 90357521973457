import { ChangeEvent, FC, useState } from 'react';
import { Box, FormControl, Select, MenuItem } from '@mui/material';
import { useSelector } from 'react-redux';
import { selectDropdown } from 'api/redux/DropdownReducer';
import { selectSponsorPrimaryColor } from 'api/redux/siteColorReducer';
import { useConfig } from 'api/redux/services/config';
import * as PdfService from './pdf.service';
import { IInvestorCapitalActivity } from './capital-activity.types';
import {
	ICSVReportConfig,
	IGetTemplateIdParams,
	IHeaderOptionsProps,
	IPeriodSelector,
	IPrintCasButtonProps,
	TemplateType,
} from './types';
import { ConfirmationModal } from 'common/components/UsersTable/user-permissions/ConfirmationModal';
import PermissionsWrapper from 'common/helpers/permissions/PermissionsWrapper';
import { Delete, GetApp, Print } from '@mui/icons-material';
import { SCOPES } from 'common/helpers/permissions/Scopes';
import {
	useDeleteCasByPeriodMutation,
	useGetTemplatesQuery,
} from 'api/redux/services/capitalActivityApi';
import { styled } from '@mui/system';
import { CSVLink } from 'react-csv';
import { BrandedButton } from 'common/components/BrandedButton';
import {
	csvExport,
	csvHeaders,
	mtdCsvExport,
	mtdCsvHeaders,
} from './CasCSVExport';
import { IconBtn } from 'common/components/IconBtn';
import {
	getDateStringFromReportingPeriod,
	getSortedCapitalActivityData,
} from './utils';

const HeaderOptionsBox = styled(Box)(() => ({
	display: 'flex',
	flexDirection: 'row',
	justifyContent: 'space-between',
	alignItems: 'center',
	fontSize: '2rem',
}));

const PeriodDropDownFormControl = styled(FormControl)(() => ({
	minWidth: 350,
	fontSize: '1rem',
}));

const PeriodSelectorControl = styled(Select)(() => ({
	width: 350,
	color: '#000000 !important',
	'& .MuiOutlinedInput-notchedOutline': {
		borderColor: '#FFFFFF !important',
	},
	'& :focus': {
		backgroundColor: '#FFFFFF',
	},
	textAlign: 'right',
	'&.MuiSelect-icon': {
		color: 'red',
	},
}));

const ButtonsBox = styled(Box)(() => ({
	float: 'right',
	height: '100%',
}));

const PrintCasButton: FC<IPrintCasButtonProps> = ({ onClick, visible }) => {
	if (!visible) return <></>;

	return (
		<IconBtn tooltip="Print Statement" onClick={onClick} customColor={true}>
			<Print />
		</IconBtn>
	);
};

const PeriodSelector: FC<IPeriodSelector> = ({
	currentReportingPeriod,
	capitalActivityData,
	loadCapitalActivity,
	visible,
	disabled,
}) => {
	const sponsorColor = useSelector(selectSponsorPrimaryColor);
	if (!visible) return <></>;

	return (
		<PeriodDropDownFormControl size="small">
			<PeriodSelectorControl
				value={currentReportingPeriod}
				variant="outlined"
				onChange={(e) =>
					loadCapitalActivity(
						e as ChangeEvent<{ name?: string | undefined; value: unknown }>,
					)
				}
				sx={{
					'& .MuiSelect-icon': {
						fill: `${sponsorColor}`,
					},
				}}
				disabled={disabled}
			>
				{capitalActivityData.map((option, i) => (
					<MenuItem value={option.reportingPeriod} key={i}>
						{option.reportingPeriod}
					</MenuItem>
				))}
			</PeriodSelectorControl>
		</PeriodDropDownFormControl>
	);
};

export const HeaderOptions: FC<IHeaderOptionsProps> = ({
	loadCapitalActivity,
	capitalActivityData,
	fundCapitalActivityData,
	currentReportingPeriod,
	setError,
	afterDelete,
	hasPermission,
	isAggregate,
}) => {
	const grants = useSelector(selectDropdown);
	const { currentInvestor, currentInvestors, currentFunds } = grants.grants;
	const { data: config } = useConfig();
	const [showConfirmDelete, setShowConfirmDelete] = useState<boolean>(false);
	const [deleteCas] = useDeleteCasByPeriodMutation();
	const { data: templates } = useGetTemplatesQuery();

	const handleDelete = async () => {
		const foundCas: IInvestorCapitalActivity | undefined =
			capitalActivityData.find(
				(cas) => cas.reportingPeriod === currentReportingPeriod,
			);

		if (!foundCas) {
			setError("Couldn't find a report for the corresponding reporting period");
			return;
		}

		await deleteCas({
			fundId: foundCas.fundId,
			investorId: foundCas.investorId,
			period: foundCas.reportingPeriod,
		});
		setShowConfirmDelete(false);
		afterDelete();
	};

	const createPDF = () => {
		if (isAggregate) {
			downloadZip();
			return;
		}

		if (capitalActivityData?.length > 0) {
			const foundCas: IInvestorCapitalActivity | undefined =
				capitalActivityData.find(
					(cas) => cas.reportingPeriod === currentReportingPeriod,
				);
			if (!foundCas) {
				setError(
					"Couldn't find a report for the corresponding reporting period",
				);
				return;
			}

			PdfService.generatePDF(foundCas.id, config?.apiBaseUrl)
				.then((response) => {
					const file = new Blob([response.data], { type: 'application/pdf' });
					const fileURL = URL.createObjectURL(file);
					window.open(fileURL);
				})
				.catch((error) => {
					setError(error);
				});
		}
	};

	const downloadZip = () => {
		PdfService.generatePDFZip(
			currentInvestors.map((investor) => investor.id),
			currentFunds.map((fund) => fund.id),
			getDateStringFromReportingPeriod(currentReportingPeriod),
			config?.apiBaseUrl,
		)
			.then((response) => {
				const file = new Blob([response.data], { type: 'application/zip' });
				const fileURL = URL.createObjectURL(file);
				const link = document.createElement('a');
				link.href = fileURL;
				link.download = 'capital-activity-statements.zip';
				document.body.appendChild(link);

				link.click();

				document.body.removeChild(link);
				window.URL.revokeObjectURL(fileURL);
			})
			.catch((error) => {
				setError(error);
			});
	};

	const getTemplateId = ({
		templates,
		fund,
	}: IGetTemplateIdParams): string | undefined => {
		if (templates && fund) {
			const template = templates.find(
				(template) => template.id === fund.templateId,
			);
			return template?.templateId;
		}

		return undefined;
	};

	const getTime = (date?: Date) => {
		return date != null ? date.getTime() : 0;
	};

	const getCasDocument = () => {
		const foundCas: IInvestorCapitalActivity | undefined =
			capitalActivityData.find(
				(cas) => cas.reportingPeriod === currentReportingPeriod,
			);
		return foundCas;
	};

	const getCSVReportConfig = (): ICSVReportConfig => {
		const template = getTemplateId({ templates, fund: getCasDocument()?.fund });
		const MTD_YTD_LTD_TEMPLATE = TemplateType.MTD;
		const CAPITAL_ACTIVITY_FILENAME =
			currentReportingPeriod + ' Capital Activity Statement.csv';
		switch (template) {
			case MTD_YTD_LTD_TEMPLATE:
				return {
					data: mtdCsvExport(getCasDocument(), currentInvestor),
					headers: mtdCsvHeaders,
					filename: CAPITAL_ACTIVITY_FILENAME,
				};

			default:
				return {
					data: csvExport(
						getCasDocument(),
						fundCapitalActivityData,
						currentInvestor,
					),
					headers: csvHeaders,
					filename: CAPITAL_ACTIVITY_FILENAME,
				};
		}
	};

	const csvReport = getCSVReportConfig();

	const CsvButton = styled(BrandedButton)(({ theme }) => ({
		maxWidth: 'fit-content',
		marginTop: '6px',
	}));

	const ExportButton: FC = () => {
		return (
			<IconBtn
				tooltip="Download Statement"
				onClick={() => {}}
				customColor={true}
			>
				<GetApp />
			</IconBtn>
		);
	};

	const DeleteButton: FC = () => {
		if (isAggregate) return <></>;

		return (
			<PermissionsWrapper scopes={[SCOPES.canDeleteCas]}>
				<IconBtn
					onClick={() => setShowConfirmDelete(true)}
					customColor={true}
					tooltip="Delete Statement"
				>
					<Delete />
				</IconBtn>
			</PermissionsWrapper>
		);
	};

	if (capitalActivityData.length === 0 || currentInvestor.id === 0)
		return <></>;

	return (
		<HeaderOptionsBox>
			<PeriodSelector
				currentReportingPeriod={currentReportingPeriod}
				capitalActivityData={getSortedCapitalActivityData(capitalActivityData)}
				loadCapitalActivity={loadCapitalActivity}
				visible={hasPermission}
				disabled={isAggregate}
			/>
			<ButtonsBox>
				<PrintCasButton onClick={() => createPDF()} visible={hasPermission} />
				<CsvButton>
					<CSVLink {...csvReport}>
						<ExportButton />
					</CSVLink>
				</CsvButton>
				<DeleteButton />
			</ButtonsBox>
			<ConfirmationModal
				isOpen={showConfirmDelete}
				onAccept={handleDelete}
				onCancel={() => setShowConfirmDelete(false)}
				title="Confirm to delete this Capital Account Statement"
				message="Are you sure to delete this Capital Account Statement?"
				saveText="Confirm"
			/>
		</HeaderOptionsBox>
	);
};

export default HeaderOptions;
