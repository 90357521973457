import { FC, useCallback } from 'react';
import { MenuItem, SelectChangeEvent } from '@mui/material';
import { onChangeInvestors, selectDropdown } from 'api/redux/DropdownReducer';
import { useDispatch, useSelector } from 'react-redux';
import { DropDownOrderValues } from 'api/redux/types';
import { InvestorDropdownBase } from './InvestorDropdownBase';
import { onShowAllUsers } from 'api/redux/DropdownReducer';
import { allowedMultipleDropdownView } from '../Helpers/utils';
import { useLocation } from 'react-router-dom';
import { MultipleInvestorDropdown } from './MultipleInvestorDropdown';

const SingleInvestorDropdown: FC = () => {
	const dispatch = useDispatch();
	const { grants, showAllUsers } = useSelector(selectDropdown);
	const {
		currentInvestor,
		currentInvestors,
		availableInvestors,
		firstDropdown,
		availableFunds,
	} = grants;

	const shouldShowPlaceholder =
		showAllUsers ||
		(firstDropdown === DropDownOrderValues.FUND && availableFunds.length === 0);

	const currentValues = currentInvestors.map((investor) =>
		investor.id.toString(),
	);

	const handleInvestorChange = useCallback(
		(event: SelectChangeEvent<unknown>) => {
			if (showAllUsers) {
				dispatch(onShowAllUsers(false));
				return;
			}

			const selectedValues = event.target.value as string[];
			const selectedInvestors = availableInvestors.filter((investor) =>
				selectedValues.includes(investor.id.toString()),
			);
			dispatch(onChangeInvestors(selectedInvestors));
		},
		[availableInvestors, currentInvestors, dispatch],
	);

	const renderValue = (selected: unknown) => {
		const selectedValues = selected as string[];

		if (selectedValues.length === 0) {
			return <em>{placeholderMessage}</em>;
		}

		return availableInvestors
			.filter((investor) => selectedValues.includes(investor.id.toString()))
			.map((investor) => investor.name)
			.join(', ');
	};

	const placeholderMessage = shouldShowPlaceholder
		? 'Please select investor(s)'
		: 'Select Investors';

	if (shouldShowPlaceholder) {
		return (
			<InvestorDropdownBase
				id="investor-selector-multi"
				value={[]}
				onChange={handleInvestorChange}
				multiple
				displayEmpty
				renderValue={renderValue}
			>
				<MenuItem value="">{placeholderMessage}</MenuItem>
			</InvestorDropdownBase>
		);
	}

	return (
		<InvestorDropdownBase
			id="investor-selector-multi"
			value={currentValues}
			onChange={handleInvestorChange}
			multiple
			displayEmpty
			renderValue={renderValue}
			title={currentInvestor.name}
		>
			{availableInvestors.map((investor) => (
				<MenuItem key={investor.id} value={investor.id.toString()}>
					{investor.name}
				</MenuItem>
			))}
		</InvestorDropdownBase>
	);
};

export const InvestorDropdown: FC = () => {
	const { pathname } = useLocation();

	const isMultipleAllowed = allowedMultipleDropdownView(pathname);
	if (isMultipleAllowed) {
		return <MultipleInvestorDropdown />;
	}

	return <SingleInvestorDropdown />;
};
