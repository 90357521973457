import { FC, useCallback, useMemo } from 'react';
import { MenuItem, SelectChangeEvent, Typography } from '@mui/material';
import { onChangeFunds, selectDropdown } from 'api/redux/DropdownReducer';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { DropDownOrderValues } from 'api/redux/types';
import { FundDropdownBase, INVESTOR_VIEW_ROUTES } from './FundDropdownBase';
import { getAllowedFunds } from './FundDropdownHelpers';
import { allowedMultipleDropdownView } from '../Helpers/utils';

export const MultipleFundDropdown: FC = () => {
	const dispatch = useDispatch();
	const { pathname } = useLocation();
	const grants = useSelector(selectDropdown);
	const {
		currentFunds,
		currentSponsor,
		availableFunds,
		firstDropdown,
		currentInvestor,
		currentInvestors,
	} = grants.grants;

	// This exists as a toggle to suggest if a placeholder prompt should be implemented instead of displayed values
	const shouldShowPlaceholder =
		firstDropdown === DropDownOrderValues.INVESTOR &&
		currentInvestors.length === 0;

	// this filters the available funds and updates the redux state with the filtered funds
	const filteredAvailableFunds = useMemo(
		() =>
			getAllowedFunds(
				firstDropdown,
				availableFunds,
				currentInvestor,
				currentInvestors,
			),
		[firstDropdown, availableFunds, currentInvestor, currentInvestors],
	);

	// this is a toggle to suggest if the dropdowns should be rendered on the investor view page
	const isInvestorViewPage = useMemo(
		() =>
			INVESTOR_VIEW_ROUTES.includes(
				pathname as typeof INVESTOR_VIEW_ROUTES[number],
			),
		[pathname],
	);

	// This functions similar to the isInvestorViewPage toggle, but determines if multipledropdown values are allowed
	const isMultipleAllowed = useMemo(
		() => allowedMultipleDropdownView(pathname),
		[pathname],
	);

	const currentFundIds = currentFunds.map((fund) => fund.id.toString());

	const handleMultipleFundChange = useCallback(
		(event: SelectChangeEvent<unknown>) => {
			const selectedValues = event.target.value as string[];
			if (selectedValues.includes('all')) {
				const newFunds =
					currentFundIds.length === filteredAvailableFunds.length
						? []
						: filteredAvailableFunds;
				dispatch(onChangeFunds(newFunds));
				return;
			}

			const selectedFunds = filteredAvailableFunds.filter((fund) =>
				selectedValues.includes(fund.id.toString()),
			);
			dispatch(onChangeFunds(selectedFunds));
		},
		[filteredAvailableFunds, currentFundIds, dispatch],
	);

	const placeholderMessage = shouldShowPlaceholder
		? 'Please select investor(s)'
		: 'Select Funds';

	const renderValue = (selected: unknown) => {
		const selectedValues = selected as string[];

		if (selectedValues.length === 0) {
			return (
				<Typography component={'em'} variant="body1">
					{' '}
					{placeholderMessage}{' '}
				</Typography>
			);
		}

		if (selectedValues.length === filteredAvailableFunds.length) {
			return 'All Funds';
		}

		return filteredAvailableFunds
			.filter((fund) => selectedValues.includes(fund.id.toString()))
			.map((fund) => fund.name)
			.join(', ');
	};

	// Toggle render option for if the placeholder message should be displayed
	// Handles render state for when firstDropdown = investor and there are no values selected
	if (shouldShowPlaceholder) {
		return (
			<FundDropdownBase
				id="fund-selector-multi"
				value={[]}
				onChange={handleMultipleFundChange}
				multiple
				displayEmpty
				disabled={true}
				renderValue={renderValue}
			>
				<MenuItem disabled value="">
					{placeholderMessage}
				</MenuItem>
			</FundDropdownBase>
		);
	}

	// Handles render state for when the dropdown should not be rendered
	if (!isMultipleAllowed || currentSponsor.id === 0 || isInvestorViewPage) {
		return null;
	}

	return (
		<FundDropdownBase
			id="fund-selector-multi"
			value={currentFundIds}
			onChange={handleMultipleFundChange}
			multiple
			displayEmpty
			renderValue={renderValue}
		>
			<MenuItem value="all">
				<Typography component={'em'} variant="body1">
					{currentFundIds.length === filteredAvailableFunds.length
						? 'Clear All'
						: 'Select All'}
				</Typography>
			</MenuItem>
			{filteredAvailableFunds.map((fund) => (
				<MenuItem key={fund.id} value={fund.id.toString()}>
					{fund.name}
				</MenuItem>
			))}
		</FundDropdownBase>
	);
};
