import { useAuth0 } from '@auth0/auth0-react';
import {
	useLazyGetInvestorCapitalActivityQuery,
	useLazyGetFundCapitalActivityQuery,
	useLazyGetInvestorCapitalActivityAggregateQuery,
	useLazyGetAvailablePeriodsQuery,
	useLazyGetFundCapitalActivityAggregateQuery,
} from 'api/redux/services/capitalActivityApi';
import { FC, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { Alert } from '@mui/material';
import { CapitalAccountStatementTable } from './Deprecated/CapitalAccountStatementTable';
import { CapitalAccountStatementHeader } from './CapitalAccountStatementHeader';
import { CapitalAccountStatementDisclaimer } from './CapitalAccountStatementDisclaimer';
import { useDispatch, useSelector } from 'react-redux';
import { selectDropdown } from 'api/redux/DropdownReducer';
import { useLazyGetInvestorPermissionsQuery } from 'api/redux/services/investors.service';
import { ReportTypeNames } from '../ui/Reports';
import {
	ICapitalAccountStatementProps,
	ICapitalActivityTableProps,
	TemplateType,
} from './types';
import {
	ICapitalActivityBase,
	IInvestorCapitalActivity,
	ITableViewProps,
} from './capital-activity.types';
import { CASTable } from './CASTable/CASTable';
import { ITDCASTable } from './ITDCASTable/ITDCASTable';
import { YTDCASTable } from './YTDCASTable/YTDCASTable';
import {
	onRemoveRedirectURL,
	redirectURLState,
} from 'api/redux/RedirectURLReducer';
import { styled } from '@mui/system';
import { useGrants } from 'common/helpers/permissions/use-grants/useGrants';
import { MTDCASTable } from './MTDCASTable/MTDCASTable';
import {
	getDateFromReportingPeriod,
	getReportingPeriodStringFromDateString,
	getSortedCapitalActivityData,
} from './utils';

const ReportsBox = styled(Box)(() => ({
	height: '100%',
	width: '100%',
	display: 'flex',
	paddingTop: '5px',
	paddingLeft: '5px',
	paddingBottom: '10px',
	flexDirection: 'column',
	justifyContent: 'space-between',
	alignItems: 'center',
}));

const TableBox = styled(Box)(() => ({
	width: 'min(100%, 1200px)',
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'flex-start',
	flexGrow: 9,
}));

const CapitalActivityTable: FC<ICapitalActivityTableProps> = ({
	visible,
	capitalActivityData,
	fundCapitalActivityData,
}) => {
	if (!visible || capitalActivityData === undefined) return <></>;

	const isFundDataRequired = () => {
		switch (capitalActivityData.capitalActivityTemplate.templateId) {
			case TemplateType.YTD:
			case TemplateType.YTD_ITD:
			case TemplateType.MTD:
				return false;
			default:
				return true;
		}
	};

	if (isFundDataRequired() && !fundCapitalActivityData) return <></>;

	const InactiveTableView: FC = () => {
		return (
			<TableBox>
				<CapitalAccountStatementTable
					data={capitalActivityData}
					fundData={fundCapitalActivityData!}
				/>
				<CapitalAccountStatementDisclaimer />
			</TableBox>
		);
	};

	const TableComponent = () => {
		switch (capitalActivityData.capitalActivityTemplate.templateId) {
			case TemplateType.YTD:
				return <YTDCASTable data={capitalActivityData} />;
			case TemplateType.YTD_ITD:
				return <ITDCASTable data={capitalActivityData} />;
			case TemplateType.MTD:
				return <MTDCASTable data={capitalActivityData} />;
			default:
				return (
					<CASTable
						data={capitalActivityData}
						fundData={fundCapitalActivityData!}
					/>
				);
		}
	};

	const ActiveTableView: FC = () => {
		return (
			<TableBox>
				<TableComponent />
				<CapitalAccountStatementDisclaimer />
			</TableBox>
		);
	};

	if (!capitalActivityData.capitalActivityTemplate.active) {
		return <InactiveTableView />;
	}

	return <ActiveTableView />;
};

const TableView: FC<ITableViewProps> = ({
	currentReportingPeriod,
	setError,
	capitalActivityData,
	loadCapitalActivity,
	fetchCapitalActivity,
	hasPermission,
	canShowTable,
	foundCapitalActivity,
	fundCapitalActivityData,
	investorPermissions,
	isAggregate,
}) => {
	return (
		<ReportsBox>
			<CapitalAccountStatementHeader
				currentReportingPeriod={currentReportingPeriod || ''}
				setError={setError}
				capitalActivityData={capitalActivityData}
				fundCapitalActivityData={fundCapitalActivityData}
				loadCapitalActivity={loadCapitalActivity}
				investorAllowedReportTypes={investorPermissions?.reportTypes || []}
				afterDelete={fetchCapitalActivity}
				hasPermission={hasPermission}
				isAggregate={isAggregate}
			/>
			<CapitalActivityTable
				visible={canShowTable}
				capitalActivityData={foundCapitalActivity}
				fundCapitalActivityData={fundCapitalActivityData}
			/>
		</ReportsBox>
	);
};

export const CapitalAccountStatement: FC<ICapitalAccountStatementProps> = ({
	period,
}) => {
	const dispatch = useDispatch();
	const redirectURL = useSelector(redirectURLState);
	const { user } = useAuth0();
	const grants = useSelector(selectDropdown);
	const { currentInvestors, currentFunds } = grants.grants;
	const { isAdmin } = useGrants();
	const [getCapitalActivity, { data: capitalActivityData = [] }] =
		useLazyGetInvestorCapitalActivityQuery();
	const [getCapitalActivityAggregate, { data: capitalActivityAggregateData }] =
		useLazyGetInvestorCapitalActivityAggregateQuery();
	const [getFundCapitalActivity, { data: fundCapitalActivityData }] =
		useLazyGetFundCapitalActivityQuery();
	const [
		getFundCapitalActivityAggregate,
		{ data: fundCapitalActivityAggregateData },
	] = useLazyGetFundCapitalActivityAggregateQuery();
	const [fetchAvailablePeriods, { data: availablePeriods }] =
		useLazyGetAvailablePeriodsQuery();
	const [error, setError] = useState<string | undefined>(undefined);
	const [currentReportingPeriod, setCurrentReportingPeriod] = useState<
		string | undefined
	>(period);
	const [fetchInvestorPermissions, investorPermissions] =
		useLazyGetInvestorPermissionsQuery();
	const [hasPermission, setHasPermission] = useState<boolean>(false);
	const [isAggregate, setIsAggregate] = useState<boolean>(
		currentInvestors.length > 1 || currentFunds.length > 1,
	);
	const [capitalActivities, setCapitalActivities] = useState<
		IInvestorCapitalActivity[]
	>([]);
	const [capitalActivity, setCapitalActivity] = useState<
		IInvestorCapitalActivity | undefined
	>(undefined);
	const [fundCapitalActivity, setFundCapitalActivity] = useState<
		ICapitalActivityBase | undefined
	>(undefined);

	const clearUrlParams = () => {
		const url = new URL(window.location.href);
		url.searchParams.delete('sponsorId');
		url.searchParams.delete('fundId');
		url.searchParams.delete('investorId');
		url.searchParams.delete('period');
		return url.toString();
	};

	useEffect(() => {
		history.replaceState(null, '', clearUrlParams());
	}, []);

	const canShowTable: boolean =
		(isAdmin || hasPermission) &&
		currentInvestors.length > 0 &&
		capitalActivity !== undefined;

	const loadCapitalActivity = (e) => {
		setCurrentReportingPeriod(e.target.value as string);
	};

	useEffect(() => {
		if (
			(currentInvestors && currentInvestors.length > 1) ||
			(currentFunds && currentFunds.length > 1)
		) {
			setIsAggregate(true);
			return;
		}

		setIsAggregate(false);
	}, [currentInvestors, currentFunds]);

	const fetchCapitalActivity = () => {
		if (isAggregate && currentReportingPeriod) {
			getCapitalActivityAggregate({
				investorIds: currentInvestors.map((i) => i.id),
				fundIds: currentFunds.map((f) => f.id),
				reportingPeriodEndDate: getDateFromReportingPeriod(
					currentReportingPeriod,
				)?.toDateString(),
			});
			return;
		}

		if (
			!currentInvestors ||
			currentInvestors.length === 0 ||
			!currentFunds ||
			currentFunds.length === 0
		) {
			return;
		}

		if (!isAggregate) {
			getCapitalActivity({
				investorId: currentInvestors[0].id,
				fundId: currentFunds[0].id,
			});
		}
	};

	useEffect(() => {
		if (!availablePeriods) return;

		setCurrentReportingPeriod(
			getReportingPeriodStringFromDateString(availablePeriods[0]),
		);
	}, [availablePeriods]);

	useEffect(() => {
		if (
			!isAggregate ||
			!currentFunds ||
			currentFunds.length === 0 ||
			!currentInvestors ||
			currentInvestors.length === 0
		)
			return;

		fetchAvailablePeriods({
			investorIds: currentInvestors.map((i) => i.id),
			fundIds: currentFunds.map((f) => f.id),
		});
	}, [isAggregate, currentInvestors, currentFunds]);

	useEffect(() => {
		if (
			!capitalActivityAggregateData ||
			!capitalActivityAggregateData.fundCapitalActivityIds
		)
			return;

		setCapitalActivity(capitalActivityAggregateData);
		setCapitalActivities([capitalActivityAggregateData]);

		getFundCapitalActivityAggregate(
			capitalActivityAggregateData.fundCapitalActivityIds,
		);
	}, [capitalActivityAggregateData]);

	useEffect(() => {
		if (isAdmin) setHasPermission(true);

		if (isAdmin || currentInvestors.length === 0) return;

		fetchInvestorPermissions({ investorId: currentInvestors[0].id });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentInvestors]);

	useEffect(() => {
		if (!investorPermissions.data?.reportTypes) return;

		const { reportTypes } = investorPermissions.data;

		setHasPermission(
			reportTypes.find(
				(rt) => rt.name === ReportTypeNames.CAPITAL_ACCOUNT_STATEMENT,
			) !== undefined,
		);
	}, [investorPermissions.data]);

	useEffect(() => {
		fetchCapitalActivity();
	}, [
		user,
		currentInvestors,
		currentFunds,
		getCapitalActivity,
		isAggregate,
		currentReportingPeriod,
	]);

	useEffect(() => {
		if (!capitalActivityData.length || (currentReportingPeriod && period))
			return;

		const sortedCapitalActivityData =
			getSortedCapitalActivityData(capitalActivityData);

		setCurrentReportingPeriod(sortedCapitalActivityData[0].reportingPeriod);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [capitalActivityData, period]);

	useEffect(() => {
		if (!capitalActivityData || capitalActivityData.length === 0) return;

		setCapitalActivities(capitalActivityData);

		let foundCas = capitalActivityData.find(
			(f) => f.reportingPeriod === currentReportingPeriod,
		);

		if (foundCas === undefined)
			foundCas = capitalActivityData.find(
				(f) => f.reportingPeriod === capitalActivityData[0].reportingPeriod,
			);

		if (foundCas === undefined) {
			setCapitalActivity(undefined);
			return;
		}

		setCapitalActivity(foundCas);

		if (foundCas.fundCapitalActivityId) {
			getFundCapitalActivity(foundCas.fundCapitalActivityId);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentReportingPeriod, capitalActivityData]);

	useEffect(() => {
		if (!fundCapitalActivityData) return;

		setFundCapitalActivity(fundCapitalActivityData);
	}, [fundCapitalActivityData]);

	useEffect(() => {
		if (!fundCapitalActivityAggregateData) return;

		setFundCapitalActivity(fundCapitalActivityAggregateData);
	}, [fundCapitalActivityAggregateData]);

	if (error)
		return (
			<Alert severity="error" variant="filled">
				{error}
			</Alert>
		);

	if (redirectURL) {
		dispatch(onRemoveRedirectURL({}));
	}

	return (
		<TableView
			currentReportingPeriod={currentReportingPeriod}
			setError={setError}
			capitalActivityData={capitalActivities}
			loadCapitalActivity={loadCapitalActivity}
			fetchCapitalActivity={fetchCapitalActivity}
			hasPermission={hasPermission}
			canShowTable={canShowTable}
			foundCapitalActivity={capitalActivity}
			fundCapitalActivityData={fundCapitalActivity}
			investorPermissions={investorPermissions.data}
			isAggregate={isAggregate}
		/>
	);
};
