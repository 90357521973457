export enum ERoutes {
	// Explicit casting of routes to minimize issues until we're fully integrated
	MultiDashboard = '/',
	MultiDocuments = '/documents',
	MultiReports = '/reports',
	MultiCasReports = '/cas-reports',

	// Resume normal routes
	Dashboard = '/',
	SpecificDocument = '/documents/:documentId',
	DocumentView = '/document-view',
	Documents = '/documents',
	Reports = '/reports',
	CasReports = '/cas-reports',
	Help = '/help',
	OrganizationView = '/organization-view',
	InvestorsView = '/investors-view',
	BulkUploadUsers = '/organizations/bulk-upload-users',
	Fund = '/fund',
	Admin = '/admin',
	OrganizationPage = '/superadmin/sponsors',
	FundPage = '/superadmin/sponsors/funds',
	BulkUpload = '/superadmin/sponsors/:organizationId/funds/:fundId/bulkUpload',
	RefreshToken = '/refresh-token',
	Logout = '/logout',
}
