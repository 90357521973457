import { ERoutes } from '../../../../ERoutes';
import { useLocation } from 'react-router-dom';
import { FC, ReactElement, useEffect } from 'react';
import { FundDropdown } from './FundDropdowns/FundDropdown';
import { InvestorDropdown } from './InvestorDropdowns/InvestorDropdown';
import { SwitchOrderButton } from './FundInvestorOrderButton';
import { matchPath } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
	onChangeDropdownOrder,
	selectDropdown,
} from 'api/redux/DropdownReducer';
import { DropDownOrderValues } from 'api/redux/types';
import { FlexDirection } from '../types';
import { useUpdateLastLoginMutation } from 'api/redux/services/userApi';
import { useAuth0 } from '@auth0/auth0-react';
import { ROLES } from 'common/helpers/permissions/Roles';
import { styled } from '@mui/system';
import { AllUsersButton } from './AllUsersButton';
import { useGrants } from 'common/helpers/permissions/use-grants/useGrants';
import { Box } from '@mui/material';

const DropdownsBox = styled(Box)({
	display: 'flex',
	flexDirection: FlexDirection.ROW,
	flexWrap: 'wrap',
	justifyContent: 'right',
	alignItems: 'center',
});

const DropdownPair: FC<{ firstDropdown: DropDownOrderValues }> = ({
	firstDropdown,
}) => (
	<>
		{firstDropdown === DropDownOrderValues.FUND ? (
			<>
				<FundDropdown />
				<InvestorDropdown />
			</>
		) : (
			<>
				<InvestorDropdown />
				<FundDropdown />
			</>
		)}
	</>
);

export const DropDowns: FC = (): ReactElement => {
	const { pathname } = useLocation();
	const dispatch = useDispatch();
	const grants = useSelector(selectDropdown);
	const {
		availableSponsors,
		currentSponsor,
		firstDropdown,
		currentFund,
		currentInvestor,
	} = grants.grants;
	const { currentRoles } = useGrants();

	const { isAuthenticated, user } = useAuth0();
	const isSpecificDocumentPage = matchPath(pathname, ERoutes.SpecificDocument);
	const isBulkUploadUsersPage = matchPath(pathname, ERoutes.BulkUploadUsers);
	const isInvestorViewPage = matchPath(pathname, ERoutes.InvestorsView);
	const isOrganizationViewPage = matchPath(pathname, ERoutes.OrganizationView);
	const [updateLastLogin] = useUpdateLastLoginMutation();

	useEffect(() => {
		if (
			!isAuthenticated ||
			!user?.sub ||
			!currentRoles ||
			currentRoles.includes(ROLES.SUPER_ADMIN) ||
			!currentSponsor.id ||
			currentSponsor.id === 0
		)
			return;

		updateLastLogin({
			sponsorId: currentSponsor.id,
			fundId: currentFund.id,
			investorId: currentInvestor.id,
		});
	}, [
		currentFund.id,
		currentInvestor.id,
		currentSponsor.id,
		isAuthenticated,
		updateLastLogin,
		user?.sub,
	]);

	const HIDE_SWITCH_BUTTON_ROUTES = [ERoutes.InvestorsView];

	const shouldHideButton = HIDE_SWITCH_BUTTON_ROUTES.some((route) =>
		matchPath(pathname, route),
	);

	const handleSwitchOrder = () => {
		const newOrder =
			firstDropdown === DropDownOrderValues.FUND
				? DropDownOrderValues.INVESTOR
				: DropDownOrderValues.FUND;
		dispatch(onChangeDropdownOrder(newOrder));
	};

	if (
		pathname.includes('admin') ||
		pathname.includes('Super Admin') ||
		!availableSponsors.length ||
		isSpecificDocumentPage ||
		isBulkUploadUsersPage ||
		isOrganizationViewPage
	)
		return <></>;

	if (isInvestorViewPage) {
		return (
			<DropdownsBox>
				<AllUsersButton />
				<InvestorDropdown />
			</DropdownsBox>
		);
	}

	return (
		<DropdownsBox>
			{!shouldHideButton && <SwitchOrderButton onClick={handleSwitchOrder} />}
			<DropdownPair firstDropdown={firstDropdown} />
		</DropdownsBox>
	);
};
