import { MainSidebarContainer } from 'common/components/drawer/MainSidebarContainer';
import { FC, useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import {
	selectNavbarMobile,
	selectNavbarTablet,
} from 'api/redux/NavBarStateReducer';
import { WebView } from './WebView';
import { onSetCurrentDocument } from 'api/redux/DocumentsReducer';
import { useNavigate } from 'react-router-dom';
import { ERoutes } from '../../../ERoutes';
import { selectDropdown } from 'api/redux/DropdownReducer';
import { useDispatch } from 'react-redux';
import { TabletView } from './TabletView';
import { MobileView } from './MobileView';

export const DocumentView: FC = () => {
	const { grants } = useSelector(selectDropdown);
	const { currentSponsor, currentFund, currentInvestor } = grants;
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const tablet = useSelector(selectNavbarTablet);
	const mobile = useSelector(selectNavbarMobile);
	const [loaded, setLoaded] = useState(false);

	const prevSponsorId = useRef(currentSponsor.id);
	const prevFundId = useRef(currentFund.id);
	const prevInvestorId = useRef(currentInvestor.id);
	const sponsorChanged = prevSponsorId.current !== currentSponsor.id;
	const fundOrInvestorChanged =
		prevFundId.current !== currentFund.id ||
		prevInvestorId.current !== currentInvestor.id;

	const DocumentViewResolver: FC = () => {
		if (tablet) return <TabletView />;
		if (mobile) return <MobileView />;

		return <WebView />;
	};

	const handleFirstLoad = () => {
		setLoaded(true);
		prevSponsorId.current = currentSponsor.id;
		prevFundId.current = currentFund.id;
		prevInvestorId.current = currentInvestor.id;
	};

	const handleUpdateRefs = () => {
		prevSponsorId.current = currentSponsor.id;
		prevFundId.current = currentFund.id;
		prevInvestorId.current = currentInvestor.id;
	};

	useEffect(() => {
		if (!loaded) {
			handleFirstLoad();
			return;
		}

		dispatch(onSetCurrentDocument(undefined));

		if (sponsorChanged) {
			navigate(ERoutes.Dashboard);
			return;
		}

		if (fundOrInvestorChanged) {
			navigate(ERoutes.Documents);
			return;
		}

		handleUpdateRefs();
	}, [currentSponsor.id, currentFund.id, currentInvestor.id]);

	return (
		<MainSidebarContainer>
			<DocumentViewResolver />
		</MainSidebarContainer>
	);
};
