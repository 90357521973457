import { IFund } from 'app/types/fund.types';
import {
	ICapitalActivityBase,
	IFundCapitalActivity,
	IInvestorCapitalActivity,
} from '../capital-activity.types';

export interface ICapitalActivityData {
	name: string;
	value: string;
}
export interface ICapitalActivity {
	attributeData: ICapitalActivityData[];
	capitalActivityTemplate: ICapitalActivityTemplate;
	createdAt: string;
	deletedAt: string;
	fund: IFund;
	fundCapitalActivityId: number;
	fundId: number;
	id: number;
	investorId: number;
	reportingPeriod: string;
	templateId: number;
	updatedAt?: string;
}
export interface ICapitalActivityTemplate {
	columns: IColumn[];
	createdAt: string;
	updatedAt: string;
	deletedAt: string;
	active: boolean;
	footer: string[];
	templateId: string;
	id: number;
	rows: IRow[];
	title: ITitle[];
}

export interface ICapitalAccountStatementTableProps {
	data?: IInvestorCapitalActivity;
	fundData: ICapitalActivityBase;
}

export enum CellValueFormats {
	UNDERLINE = 'UNDERLINE',
	NUMBER = 'NUMBER',
	CURRENCY = 'CURRENCY',
	DOUBLE_UNDERLINE = 'DOUBLE_UNDERLINE',
	TOP_LINE = 'TOP_LINE',
	THICK_UNDERLINE = 'THICK_UNDERLINE',
	PERCENTAGE = 'PERCENTAGE',
}

export enum INullBehavior {
	DASH = 'DASH',
	BLANK = 'BLANK',
	ZERO = 'ZERO',
	DOLLAR_SIGN_AND_DASH = 'DOLLAR SIGN AND DASH',
}

export interface ITitle {
	text: string;
	formats: string[];
}
export interface ILabel {
	text: string;
	formats: Array<'BOLD' | 'INDENT'>;
}

export enum EHiddenBehavior {
	HIDE_WHEN_NULL = 'HIDE_WHEN_NULL',
	SHOW_AS_ZERO_WHEN_IN_FUND_LEVEL = 'SHOW_AS_ZERO_WHEN_IN_FUND_LEVEL',
}
export interface IRow {
	label: ILabel;
	nullBehavior: INullBehavior;
	valueFormats: CellValueFormats;
	hiddenBehavior?: EHiddenBehavior[];
}

export enum EColumnTitle {
	FUND_LEVEL = 'Fund Level',
	INVESTOR_LEVEL = 'Investor Level',
	PERIOD_ENDED = 'For the period ended {{REPORTING_PERIOD}}',
}

export interface IColumnTitle {
	text: EColumnTitle;
	formats: CellValueFormats[];
}

export enum ETimePeriodSuffixes {
	ITD = 'ITD',
	YTD = 'YTD',
	PTD = 'PTD',
	MTD = 'MTD',
	NONE = 'None',
}

export interface IColumn {
	timePeriodSuffix: ETimePeriodSuffixes;
	title: IColumnTitle[];
}

export enum TemplateType {
	ITD = 'YTD & ITD w/ Fund Level',
	NO_ITD = 'YTD w/ Fund Level',
}

export const TemplateTypesToColSpan = {
	[TemplateType.ITD]: 2,
	[TemplateType.NO_ITD]: 1,
};

export enum RowNames {
	OPENING_CAPITAL_ACCOUNT = 'Opening Capital Account',
	CONTRIBUTIONS = 'Contributions',
	TRANSFERS = 'Transfers',
	NET_OPERATING_INCOME_EXPENSE = 'Net Operating Income (Expense)',
	REALIZED_INVESTMENT_GAIN_LOSS = 'Realized Investment Gain (Loss)',
	UNREALIZED_INVESTMENT_GAIN_LOSS = 'Unrealized Investment Gain (Loss)',
	SYNDICATION_COSTS = 'Syndication Costs',
	DISTRIBUTIONS = 'Distributions',
	CLOSING_CAPITAL_ACCOUNT = 'Closing Capital Account',
	CAPITAL_COMMITMENT_SUMMARY = 'Capital Commitment Summary:',
	TOTAL_COMMITMENT = 'Total Commitment',
	TOTAL_CONTRIBUTED = 'Total Contributed',
	CONTRIBUTIONS_RECEIVABLE = 'Contributions Receivable',
	RECALLABLE_DISTRIBUTIONS = 'Recallable Distributions',
	REMAINING_UNFUNDED_COMMITMENT = 'Remaining Unfunded Commitment',
}

export const FallbackKeys = {
	REMAINING_COMMITMENT: [
		'Remaining Commitment',
		'Remaining Unfunded Commitment',
		'Unfunded Commitment',
	],
	CONTRIBUTIONS_ITD: ['Contributions (ITD)'],
	CONTRIBUTIONS_RECEIVABLE: [
		'Contributions Receivable',
		'Contributions Receivable (Prepaid Contributions)',
		'Contributions Receivable / (Prepaid Contributions)',
	],
	TOTAL_CONTRIBUTIONS: [
		'Total Contributed',
		'Total Contributions',
		'Capital Called',
	],
	TOTAL_COMMITMENT: ['Total Commitment'],
};

export const RowNamesToFallbackKeys = {
	[RowNames.REMAINING_UNFUNDED_COMMITMENT]: 'REMAINING_COMMITMENT',
	[RowNames.CONTRIBUTIONS]: 'CONTRIBUTIONS_ITD',
	[RowNames.CONTRIBUTIONS_RECEIVABLE]: 'CONTRIBUTIONS_RECEIVABLE',
	[RowNames.TOTAL_CONTRIBUTED]: 'TOTAL_CONTRIBUTIONS',
	[RowNames.TOTAL_COMMITMENT]: 'TOTAL_COMMITMENT',
};

export const CapitalCommitmentTableLabels: RowNames[] = [
	RowNames.TOTAL_COMMITMENT,
	RowNames.TOTAL_CONTRIBUTED,
	RowNames.CONTRIBUTIONS_RECEIVABLE,
	RowNames.RECALLABLE_DISTRIBUTIONS,
	RowNames.REMAINING_UNFUNDED_COMMITMENT,
];

export const RowLabels = {
	[RowNames.OPENING_CAPITAL_ACCOUNT]: 'Opening Capital Account',
	[RowNames.CONTRIBUTIONS]: 'Contributions',
	[RowNames.TRANSFERS]: 'Transfers',
	[RowNames.NET_OPERATING_INCOME_EXPENSE]: 'Net Operating Income (Expense)',
	[RowNames.REALIZED_INVESTMENT_GAIN_LOSS]: 'Realized Investment Gain (Loss)',
	[RowNames.UNREALIZED_INVESTMENT_GAIN_LOSS]:
		'Unrealized Investment Gain (Loss)',
	[RowNames.SYNDICATION_COSTS]: 'Syndication Costs',
	[RowNames.DISTRIBUTIONS]: 'Distributions',
	[RowNames.CLOSING_CAPITAL_ACCOUNT]: 'Closing Capital Account',
	[RowNames.CAPITAL_COMMITMENT_SUMMARY]: 'Capital Commitment Summary:',
	[RowNames.TOTAL_COMMITMENT]: 'Capital Commitment',
	[RowNames.TOTAL_CONTRIBUTED]: 'Capital Called',
	[RowNames.CONTRIBUTIONS_RECEIVABLE]:
		'Contributions Receivable (Prepaid Contributions)',
	[RowNames.RECALLABLE_DISTRIBUTIONS]: 'Recallable Distributions',
	[RowNames.REMAINING_UNFUNDED_COMMITMENT]: 'Remaining Commitment',
};
export interface ITableHeadersProps {
	capitalActivityTemplate: ICapitalActivityTemplate;
	reportingPeriod?: string;
}

export interface ISecondHeaderRowProps {
	capitalActivityTemplate: ICapitalActivityTemplate;
}

export interface ICASTableBodyProps {
	capitalActivityTemplate: ICapitalActivityTemplate;
	CASData: ICapitalActivityData[];
	fundData: ICapitalActivityBase;
}

export interface ICASTableRowProps {
	row: IRow;
	columns: IColumn[];
	CASData: ICapitalActivityData[];
	fundData: ICapitalActivityBase;
	templateType: TemplateType;
}

export interface ICASTableCellProps {
	row: IRow;
	column: IColumn;
	CASData: ICapitalActivityData[];
	fundData: ICapitalActivityBase;
	templateType: TemplateType;
}

export interface TemplateHelper {
	shouldRenderHiddableRow: (
		row: IRow,
		CASData: ICapitalActivityData[],
		fundData: ICapitalActivityData[],
	) => boolean;
}
