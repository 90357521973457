import {
	GrantUpdates,
	DropDownOrderValues,
	InitialSponsorStateParams,
	FundChangeParams,
	InvestorChangeParams,
	UserGrantsChangeParams,
	ShowAllUsersParams,
} from '../types';
import {
	sortByName,
	getFirstItemOrEmpty,
	getAllAvailableInvestors,
	getAvailableFundsForInvestorOrder,
} from './DropdownReducerHelpers';
import { loadFromCookies } from './CookieHelpers';
import { IFundGrant } from '@redux/services/userApi';

export const handleFundChange = ({
	selectedFund,
	currentState,
	emptyInvestor,
}: FundChangeParams): GrantUpdates => {
	const sortedInvestors = sortByName(selectedFund.investors);

	const isInvestorInNewFund = selectedFund.investors.some(
		(investor) => investor.id === currentState.currentInvestor.id,
	);

	return {
		currentFund: selectedFund,
		availableInvestors: sortedInvestors,
		currentInvestor: isInvestorInNewFund
			? currentState.currentInvestor
			: getFirstItemOrEmpty(sortedInvestors, emptyInvestor),
	};
};

export const handleInvestorChange = ({
	selectedInvestor,
	currentState,
	emptyFund,
}: InvestorChangeParams): GrantUpdates => {
	if (currentState.firstDropdown !== DropDownOrderValues.INVESTOR) {
		return { currentInvestor: selectedInvestor };
	}

	const availableFunds = getAvailableFundsForInvestorOrder(
		currentState.currentSponsor,
		selectedInvestor.id,
	);

	return {
		currentInvestor: selectedInvestor,
		availableFunds,
		currentFund: availableFunds.some(
			(fund) => fund.id === currentState.currentFund.id,
		)
			? currentState.currentFund
			: getFirstItemOrEmpty(availableFunds, emptyFund),
	};
};

export const handleUserGrantsChange = ({
	userGrants,
	loadCookies,
	emptyInvestor,
}: UserGrantsChangeParams): GrantUpdates => {
	const sortedSponsors = sortByName(userGrants);

	if (!loadCookies) {
		return { availableSponsors: sortedSponsors };
	}

	const { sponsor, fund, investor, firstDropdown } =
		loadFromCookies(userGrants);

	if (!sponsor) {
		return {
			availableSponsors: sortedSponsors,
			firstDropdown,
		};
	}

	const sortedFunds = sortByName(sponsor.funds);
	const allInvestors = getAllAvailableInvestors(sponsor);
	const sortedAllInvestors = sortByName(allInvestors);

	if (!fund) {
		return {
			availableSponsors: sortedSponsors,
			currentSponsor: sponsor,
			availableFunds: sortedFunds,
			availableInvestors: sortedAllInvestors,
			firstDropdown,
		};
	}

	const sortedInvestors = sortByName(fund.investors);
	const validInvestor =
		investor && sortedInvestors.some((inv) => inv.id === investor.id)
			? investor
			: getFirstItemOrEmpty(sortedInvestors, emptyInvestor);

	return {
		availableSponsors: sortedSponsors,
		currentSponsor: sponsor,
		currentFund: fund,
		currentFunds: [fund],
		currentInvestor: validInvestor,
		currentInvestors: [validInvestor],
		availableFunds: sortedFunds,
		availableInvestors: allInvestors,
		firstDropdown,
	};
};

export const handleShowAllUsers = ({
	showAll,
	currentState,
}: ShowAllUsersParams): GrantUpdates => ({
	...currentState,
	availableInvestors: showAll
		? currentState.availableInvestors
		: sortByName(currentState.currentFund.investors),
});
