import { VisibilityOutlined } from '@mui/icons-material';
import { Box, Card, Grid, Paper, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { defaultColor } from 'api/redux/siteColorReducer';

export const DashboardContainer = styled(Grid)(() => ({
	height: '100%',
	margin: '7px 2px 5px 2px',
}));

export const StyledPaper = styled(Paper)(({ theme }) => ({
	padding: theme.spacing(2),
	borderRadius: 5,
	height: '100%',
	overflow: 'auto',
	border: '1px solid #D0D7DE',
	marginTop: '-5px',
}));

export const ActivitySummaryGrid = styled(Grid)(() => ({
	width: 'calc(100vw - 100px)',
}));

export const ContributionsGrid = styled(Grid)(() => ({
	width: 'calc(100vw - 100px)',
}));

export const AnnouncementsGrid = styled(Grid)(() => ({
	width: 'calc(100vw - 100px)',
}));

export const ActivitySummaryBox = styled(Box)({
	height: '100%',
	display: 'flex',
	flexDirection: 'column',
});

export const HeaderContainer = styled(Box)({
	width: '100%',
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'space-between',
	flexGrow: 1,
	padding: 0,
});

export const ViewMoreDetailsContainer = styled(Box)({
	padding: 0,
	display: 'flex',
	flexDirection: 'row',
	justifyContent: 'flex-end',
});

export const ViewMoreDetailsItems = styled(Box)({
	padding: 0,
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'center',
});

export const ViewMoreDetailsIcon = styled(VisibilityOutlined)({
	color: defaultColor,
});

export const ViewMoreDetailsText = styled(Typography)({
	color: defaultColor,
});

export const SummaryGrid = styled(Grid)({
	flexGrow: 19,
});

export const SummaryCard = styled(Card)({
	margin: 5,
	height: '100%',
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
});

export const CapitalActivityHeader = styled(Typography)({
	fontSize: '1rem',
	fontWeight: 500,
	color: '#1F1F1F',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
});

export const CapitalActivitySubHeader = styled(Typography)({
	fontSize: '1rem',
	fontWeight: 500,
	color: '#1F1F1F',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	marginBottom: '1rem',
});

export const CapitalActivityContent = styled(Typography)(({ theme }) => ({
	fontSize: '1.6rem',
	fontWeight: 400,
	color: theme.palette.primary.main,
	display: 'flex',
	justifyContent: 'center',
}));

export const PeriodEndingText = styled(Typography)({
	fontSize: '1rem',
	fontWeight: 400,
	color: '#1F1F1F',
	display: 'flex',
});
