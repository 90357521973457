import { FC, useCallback, useMemo } from 'react';
import { MenuItem, SelectChangeEvent, Typography } from '@mui/material';
import { onChangeInvestors, selectDropdown } from 'api/redux/DropdownReducer';
import { useDispatch, useSelector } from 'react-redux';
import { DropDownOrderValues } from 'api/redux/types';
import { InvestorDropdownBase } from './InvestorDropdownBase';

export const MultipleInvestorDropdown: FC = () => {
	const dispatch = useDispatch();
	const grants = useSelector(selectDropdown);
	const {
		currentInvestor,
		currentInvestors,
		availableInvestors,
		firstDropdown,
		currentFunds,
	} = grants.grants;

	const shouldShowPlaceholder =
		firstDropdown === DropDownOrderValues.FUND && currentFunds.length === 0;

	const currentInvestorIds = useMemo(
		() => currentInvestors.map((investor) => investor.id.toString()),
		[currentInvestors],
	);

	const handleInvestorChange = useCallback(
		(event: SelectChangeEvent<unknown>) => {
			const selectedValues = Array.isArray(event.target.value)
				? event.target.value
				: [event.target.value];

			if (selectedValues.includes('all')) {
				const newInvestors =
					currentInvestorIds.length === availableInvestors.length
						? []
						: availableInvestors;
				dispatch(onChangeInvestors(newInvestors));
				return;
			}

			const selectedInvestors = availableInvestors.filter((investor) =>
				selectedValues.includes(investor.id.toString()),
			);

			dispatch(onChangeInvestors(selectedInvestors));
		},
		[availableInvestors, currentInvestorIds, dispatch],
	);

	const renderValue = useCallback(
		(selected: unknown) => {
			const selectedValues = selected as string[];
			if (selectedValues.length === 0) {
				return (
					<Typography component={'em'} variant="body1">
						{shouldShowPlaceholder
							? 'Please select fund(s) first'
							: 'Select Investors'}
					</Typography>
				);
			}

			// Show "All Investors" when all are selected
			if (selectedValues.length === availableInvestors.length) {
				return 'All Investors';
			}

			return availableInvestors
				.filter((investor) => selectedValues.includes(investor.id.toString()))
				.map((investor) => investor.name)
				.join(', ');
		},
		[availableInvestors, shouldShowPlaceholder],
	);

	const menuItems = useMemo(() => {
		if (shouldShowPlaceholder) {
			return [
				<MenuItem key="placeholder" disabled value="">
					Please select fund(s) first
				</MenuItem>,
			];
		}

		return [
			<MenuItem key="all" value="all">
				<Typography component={'em'} variant="body1">
					{currentInvestorIds.length === availableInvestors.length
						? 'Clear All'
						: 'Select All'}
				</Typography>
			</MenuItem>,
			...availableInvestors.map((investor) => (
				<MenuItem key={investor.id} value={investor.id.toString()}>
					{investor.name}
				</MenuItem>
			)),
		];
	}, [availableInvestors, currentInvestorIds.length, shouldShowPlaceholder]);

	return (
		<InvestorDropdownBase
			id="investor-selector-multi"
			value={currentInvestorIds.length ? currentInvestorIds : []}
			onChange={handleInvestorChange}
			multiple
			displayEmpty
			disabled={shouldShowPlaceholder}
			renderValue={renderValue}
			title={currentInvestor.name}
		>
			{menuItems}
		</InvestorDropdownBase>
	);
};
