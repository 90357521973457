import { STORAGE_KEYS } from 'api/redux/constants/StorageKeys';
import axios from 'axios';

interface IPDFResponse {
	data: Blob;
}

export const generatePDF = async (
	capitalActivityId: number,
	baseUrl?: string,
): Promise<IPDFResponse> => {
	return await axios(`${baseUrl}/capital-activity/generate-pdf`, {
		method: 'POST',
		headers: {
			Authorization: `Bearer ${sessionStorage.getItem(
				STORAGE_KEYS.TOKEN.COOKIE,
			)}`,
		},
		data: { capitalActivityId },
		responseType: 'blob',
	});
};

export const generatePDFZip = async (
	investorIds: number[],
	fundIds: number[],
	reportingPeriod: string,
	baseUrl?: string,
): Promise<IPDFResponse> => {
	return await axios(`${baseUrl}/capital-activity/generate-pdf-zip`, {
		method: 'POST',
		headers: {
			Authorization: `Bearer ${sessionStorage.getItem('token')}`,
		},
		data: { investorIds, fundIds, reportingPeriod },
		responseType: 'blob',
	});
};
