import { IFundGrant, IInvestorGrant } from '@services/userApi';
import { DropDownOrderValues } from 'api/redux/types';

export function getAllowedFunds(
	firstDropdown: string,
	availableFunds: IFundGrant[],
	currentInvestor: IInvestorGrant,
	currentInvestors: IInvestorGrant[],
): IFundGrant[] {
	const hasInvestor = (fund: IFundGrant, investors: IInvestorGrant[]) =>
		fund.investors.some((fundInvestor) =>
			investors.some((investor) => fundInvestor.id === investor.id),
		);

	const filterFunds = (): IFundGrant[] => {
		if (firstDropdown !== DropDownOrderValues.INVESTOR) return availableFunds;

		const investorsToCheck =
			currentInvestors.length > 0 ? currentInvestors : [currentInvestor];

		return availableFunds.filter((fund) => hasInvestor(fund, investorsToCheck));
	};
	return filterFunds();
}
