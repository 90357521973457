import { GroupAdd } from '@mui/icons-material';
import { ERoutes } from '../../../../ERoutes';
import { selectDropdown } from 'api/redux/DropdownReducer';
import { IconBtn } from 'common/components/IconBtn';
import PermissionsWrapper from 'common/helpers/permissions/PermissionsWrapper';
import { SCOPES } from 'common/helpers/permissions/Scopes';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';

export const BulkUploadUsersButton: FC = () => {
	const { pathname } = useLocation();
	const isInvestorViewPage = matchPath(pathname, ERoutes.InvestorsView);
	const { showAllUsers } = useSelector(selectDropdown);
	const navigate = useNavigate();

	const handleClick = () => {
		navigate(`/organizations/bulk-upload-users`);
	};

	if (!(isInvestorViewPage && showAllUsers)) return <></>;

	return (
		<PermissionsWrapper scopes={[SCOPES.canBulkUploadUsers]}>
			<IconBtn
				onClick={handleClick}
				customColor={true}
				tooltip="Bulk Upload Users"
			>
				<GroupAdd />
			</IconBtn>
		</PermissionsWrapper>
	);
};
