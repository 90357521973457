import { FC } from 'react';
import {
	Typography,
	Box,
	Dialog,
	DialogTitle,
	DialogActions,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { selectDropdown } from 'api/redux/DropdownReducer';
import { useLocation, useResolvedPath } from 'react-router-dom';
import {
	useRemoveUserFromInvestorMutation,
	useRemoveUserFromSponsorMutation,
} from 'api/redux/services/organizations.service';
import { ERoutes } from '../../../../ERoutes';
import { IDeleteUserModal } from '../types';
import { BrandedButton } from 'common/components/BrandedButton';

export const DeleteUserModal: FC<IDeleteUserModal> = ({
	userId,
	isOpen,
	handleClose,
	afterDelete,
}) => {
	const grants = useSelector(selectDropdown);
	const location = useLocation();
	const resolvedPath = useResolvedPath(location.pathname);
	const path = resolvedPath.pathname;
	const [removeInvestorUser] = useRemoveUserFromInvestorMutation();
	const [removeSponsorUser] = useRemoveUserFromSponsorMutation();
	const { pathname } = useLocation();
	const isOrganizationPage = [ERoutes.OrganizationView].includes(
		pathname as ERoutes,
	);
	const subject: string = isOrganizationPage ? 'sponsor' : 'investor';
	const title: string = isOrganizationPage ? 'Sponsor' : 'Investor';

	const handleSubmit = async () => {
		if (!userId) return;

		const currentSelectedGrant = grants.grants;
		const investorId = currentSelectedGrant.currentInvestor.id;
		const sponsorId = currentSelectedGrant.currentSponsor.id;

		const ops = {
			[ERoutes.InvestorsView]: async () => {
				await removeInvestorUser({
					sponsorId,
					investorId,
					userId,
				});
				await afterDelete();
			},
			[ERoutes.OrganizationView]: async () => {
				await removeSponsorUser({ sponsorId, userId });
				await afterDelete();
			},
		};

		ops[path]();

		handleClose();
	};

	return (
		<Dialog open={isOpen} onClose={handleClose} fullWidth maxWidth="sm">
			<DialogTitle>Remove User From {title}</DialogTitle>
			<Box sx={{ pl: 3, pr: 3, pb: 3 }}>
				<Typography>
					Are you sure you want to remove this user from the {subject}?
				</Typography>
				<DialogActions>
					<BrandedButton onClick={handleClose}>Cancel</BrandedButton>
					<BrandedButton variant="contained" onClick={handleSubmit}>
						Remove User
					</BrandedButton>
				</DialogActions>
			</Box>
		</Dialog>
	);
};
