import { ERoutes } from 'ERoutes';

const MULTIPLE_SELECT_ROUTES = [
	ERoutes.MultiDashboard,
	ERoutes.MultiDocuments,
	ERoutes.MultiReports,
	ERoutes.MultiCasReports,
] as const;

export const allowedMultipleDropdownView = (pathname: string): boolean => {
	return MULTIPLE_SELECT_ROUTES.includes(
		pathname as typeof MULTIPLE_SELECT_ROUTES[number],
	);
};
