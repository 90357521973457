import { ICapitalActivityBase } from '../capital-activity.types';
import { ITDTemplateHelper } from './ITDHelper.tsx';
import { NOITDTemplateHelper } from './NOITDHelper';
import {
	EHiddenBehavior,
	ICapitalActivityData,
	IRow,
	TemplateHelper,
	TemplateType,
} from './types';

const helperClass = (templateType: TemplateType): TemplateHelper => {
	const map = {
		[TemplateType.ITD]: ITDTemplateHelper,
		[TemplateType.NO_ITD]: NOITDTemplateHelper,
	};

	return new map[templateType]();
};

export const filterRows = (
	rows: IRow[],
	CASData: ICapitalActivityData[],
	fundData: ICapitalActivityBase,
	templateType: TemplateType,
): IRow[] => {
	const finalRows: IRow[] = [];

	for (let i = 0; i < rows.length; i++) {
		const row = rows[i];

		if (
			!row.hiddenBehavior ||
			!row.hiddenBehavior.includes(EHiddenBehavior.HIDE_WHEN_NULL)
		) {
			finalRows.push(row);
			continue;
		}

		if (
			row.hiddenBehavior &&
			row.hiddenBehavior.includes(EHiddenBehavior.HIDE_WHEN_NULL)
		) {
			if (
				helperClass(templateType).shouldRenderHiddableRow(
					row,
					CASData,
					fundData.attributeData,
				)
			) {
				finalRows.push(row);
			}
		}
	}

	return finalRows;
};
