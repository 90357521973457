import { FC, ReactElement, useState } from 'react';
import { useSelector } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';
import {
	Theme,
	CSSObject,
	List,
	Box,
	styled,
	ListItemButton,
} from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import { SCOPES } from 'common/helpers/permissions/Scopes';
import PermissionsWrapper from 'common/helpers/permissions/PermissionsWrapper';
import { ERoutes } from '../../../ERoutes';
import { Analytics, Folder, Group, Home, Person } from '@mui/icons-material';
import { selectScreenWidth } from 'api/redux/NavBarStateReducer';
import { DROPDOWNS_TOTAL_LENGTH } from '../navbar/types';
import { useLocalUser } from 'common/helpers/permissions/useLocalUser';

const drawerWidth = 165;

const openedMixin = (theme: Theme): CSSObject => ({
	width: drawerWidth,
	transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.enteringScreen,
	}),
	overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
	transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	overflowX: 'hidden',
	width: `calc(${theme.spacing(6)} + 4px)`,
});

const Drawer = styled(MuiDrawer, {
	shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
	width: drawerWidth,
	flexShrink: 0,
	boxSizing: 'border-box',
	...(open && {
		...openedMixin(theme),
		'& .MuiDrawer-paper': openedMixin(theme),
	}),
	...(!open && {
		...closedMixin(theme),
		'& .MuiDrawer-paper': closedMixin(theme),
	}),
}));

const ItemBox = styled(Box)({
	marginRight: 15,
});

interface INavItem {
	text: string;
	icon: ReactElement;
	to: string;
	isHiddenOnMobile: boolean;
}

export const NavDrawer: FC = () => {
	const [drawerOpen, setDrawerOpen] = useState(false);
	const navigate = useNavigate();
	const { currentUser } = useLocalUser();
	const screenWidth = useSelector(selectScreenWidth);
	const isMobile = screenWidth < DROPDOWNS_TOTAL_LENGTH;

	const navItems: INavItem[] = [
		{
			text: 'Home',
			icon: <Home sx={{ color: '#353535', width: '23px', height: '23px' }} />,
			to: ERoutes.Dashboard,
			isHiddenOnMobile: true,
		},
		{
			text: 'Documents',
			icon: <Folder sx={{ color: '#353535', width: '23px', height: '23px' }} />,
			to: ERoutes.Documents,
			isHiddenOnMobile: false,
		},
		{
			text: 'Reports',
			icon: (
				<Analytics sx={{ color: '#353535', width: '23px', height: '23px' }} />
			),
			to: ERoutes.Reports,
			isHiddenOnMobile: false,
		},
	];

	const blurStyle = !currentUser?.initialLogin ? { filter: 'blur(5px)' } : {};

	return (
		<Drawer
			onMouseEnter={() => setDrawerOpen(true)}
			onMouseLeave={() => setDrawerOpen(false)}
			variant="permanent"
			open={drawerOpen}
			style={{ ...blurStyle }}
		>
			<List style={{ marginTop: '70px' }}>
				{navItems.map((link) =>
					link.isHiddenOnMobile && isMobile ? (
						<></>
					) : (
						<ListItemButton
							key={link.text}
							sx={{ paddingY: '15px' }}
							onClick={() => navigate(link.to, { replace: true })}
						>
							<ItemBox>{link.icon}</ItemBox>

							<NavLink
								to={link.to}
								style={({ isActive }) => ({
									fontSize: '16px',
									color: '#353535',
									fontWeight: isActive ? 'bold' : 'normal',
								})}
							>
								{link.text}
							</NavLink>
						</ListItemButton>
					),
				)}
				<PermissionsWrapper
					scopes={[SCOPES.canSeeInvestorUsersOption]}
					checkAllSponsorRoles={true}
				>
					{isMobile ? (
						<></>
					) : (
						<ListItemButton
							sx={{ paddingY: '15px' }}
							onClick={() => navigate(ERoutes.InvestorsView, { replace: true })}
						>
							<ItemBox>
								<Person
									sx={{ color: '#353535', width: '23px', height: '23px' }}
								/>
							</ItemBox>

							<NavLink
								to={ERoutes.InvestorsView}
								style={({ isActive }) => ({
									fontSize: '16px',
									color: '#353535',
									fontWeight: isActive ? 'bold' : 'normal',
								})}
							>
								Investor
							</NavLink>
						</ListItemButton>
					)}
				</PermissionsWrapper>

				<PermissionsWrapper
					scopes={[SCOPES.canSeeOrganizationUsersOption]}
					checkAllSponsorRoles={true}
				>
					<ListItemButton
						sx={{ paddingY: '15px' }}
						onClick={() =>
							navigate(ERoutes.OrganizationView, { replace: true })
						}
					>
						<ItemBox>
							<Group sx={{ color: '#353535', width: '23px', height: '23px' }} />
						</ItemBox>

						<NavLink
							to={ERoutes.OrganizationView}
							style={({ isActive }) => ({
								fontSize: '16px',
								color: '#353535',
								fontWeight: isActive ? 'bold' : 'normal',
							})}
						>
							Sponsor
						</NavLink>
					</ListItemButton>
				</PermissionsWrapper>

				<PermissionsWrapper
					scopes={[SCOPES.canSeeAdminMenuOption]}
					checkAllSponsorRoles={true}
				>
					<ListItemButton
						sx={{ paddingY: '15px' }}
						onClick={() => navigate(ERoutes.Admin, { replace: true })}
					>
						<ItemBox>
							<ManageAccountsIcon
								sx={{ color: '#353535', width: '23px', height: '23px' }}
							/>
						</ItemBox>
						<NavLink
							to={ERoutes.Admin}
							style={({ isActive }) => ({
								fontSize: '16px',
								color: '#353535',
								fontWeight: isActive ? 'bold' : 'normal',
							})}
						>
							Admin
						</NavLink>
					</ListItemButton>
				</PermissionsWrapper>
			</List>
		</Drawer>
	);
};
