import { FC, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Announcements from './Announcements';
import ActivitySummary from './ActivitySummary';
import Contributions from './Contributions';
import { useAuth0 } from '@auth0/auth0-react';
import { selectDropdown } from 'api/redux/DropdownReducer';
import { selectNavbarMobile } from 'api/redux/NavBarStateReducer';
import {
	useLazyGetInvestorCapitalActivityAggregateQuery,
	useLazyGetAvailablePeriodsQuery,
} from 'api/redux/services/capitalActivityApi';
import { MainSidebarContainer } from 'common/components/drawer/MainSidebarContainer';
import { PrivacyPolicy } from './PrivacyPolicy';
import {
	onSetRedirectURL,
	redirectURLState,
} from 'api/redux/RedirectURLReducer';
import { Navigate } from 'react-router-dom';
import { useLocalUser } from 'common/helpers/permissions/useLocalUser';
import {
	ActivitySummaryGrid,
	AnnouncementsGrid,
	ContributionsGrid,
	DashboardContainer,
	StyledPaper,
} from './styles';
import { STORAGE_KEYS } from 'api/redux/constants/StorageKeys';
import { IInvestorCapitalActivityAggregate } from 'modules/reports/CapitalAccountStatement/capital-activity.types';

export const Dashboard: FC = () => {
	const grants = useSelector(selectDropdown);
	const mobile = useSelector(selectNavbarMobile);
	const { currentInvestors, currentFunds } = grants.grants;
	const { currentUser } = useLocalUser();
	const [getAggregatedCAS, capitalActivity] =
		useLazyGetInvestorCapitalActivityAggregateQuery();
	const [getAvailablePeriods] = useLazyGetAvailablePeriodsQuery();
	const [currentCAS, setCurrentCAS] = useState<
		IInvestorCapitalActivityAggregate | undefined
	>(undefined);
	const { user } = useAuth0();
	const dispatch = useDispatch();
	const backgroundStyle = !currentUser?.initialLogin
		? { filter: 'blur(5px)' }
		: {};
	const redirectURL = useSelector(redirectURLState);

	const fetchAggregatedCAS = () => {
		getAvailablePeriods({
			fundIds: currentFunds.map((f) => f.id),
			investorIds: currentInvestors.map((i) => i.id),
		}).then((response) => {
			const { data: periods } = response;

			if (!periods || periods.length === 0) {
				console.error('Periods is either undefined or empty');
				return;
			}

			const [selectedPeriod] = periods;

			getAggregatedCAS({
				investorIds: currentInvestors.map((i) => i.id),
				fundIds: currentFunds.map((f) => f.id),
				reportingPeriodEndDate: selectedPeriod,
			});
		});
	};

	useEffect(() => {
		if (currentFunds.length === 0 || currentInvestors.length === 0) {
			setCurrentCAS(undefined);
			return;
		}

		fetchAggregatedCAS();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentInvestors, currentFunds]);

	useEffect(() => {
		if (!capitalActivity.data) return;

		setCurrentCAS(capitalActivity.data);
	}, [capitalActivity.data]);

	useEffect(() => {
		const url = sessionStorage.getItem(STORAGE_KEYS.REDIRECT_URL.COOKIE);

		if (url) {
			sessionStorage.removeItem(STORAGE_KEYS.REDIRECT_URL.COOKIE);
			dispatch(onSetRedirectURL(url));
		}
	});

	useEffect(() => {
		if (mobile) {
			dispatch(onSetRedirectURL('/documents'));
		}
	}, [dispatch, mobile]);

	if (redirectURL && currentUser?.initialLogin)
		return <Navigate to={redirectURL} />;

	return (
		<MainSidebarContainer>
			<PrivacyPolicy />
			{user && capitalActivity && (
				<DashboardContainer container spacing={1} style={backgroundStyle}>
					<ActivitySummaryGrid
						item
						xs={12}
						sx={{
							height: mobile ? 'calc(100vh/3 - 35px)' : 'calc(100vh/3 - 25px)',
						}}
					>
						<StyledPaper elevation={0}>
							<ActivitySummary capitalActivityData={currentCAS} />
						</StyledPaper>
					</ActivitySummaryGrid>
					<ContributionsGrid
						item
						xs={6}
						sx={{
							height: mobile ? 'calc(200vh/3 - 65px)' : 'calc(200vh/3 - 50px)',
						}}
					>
						<StyledPaper elevation={0}>
							<Contributions capitalActivityData={currentCAS} />
						</StyledPaper>
					</ContributionsGrid>
					<AnnouncementsGrid
						item
						xs={6}
						sx={{
							height: mobile ? 'calc(200vh/3 - 65px)' : 'calc(200vh/3 - 50px)',
						}}
					>
						<StyledPaper elevation={0}>
							<Announcements />
						</StyledPaper>
					</AnnouncementsGrid>
				</DashboardContainer>
			)}
		</MainSidebarContainer>
	);
};

export default Dashboard;
