import { FC, useCallback, useMemo } from 'react';
import { MenuItem, SelectChangeEvent } from '@mui/material';
import { onChangeFund, selectDropdown } from 'api/redux/DropdownReducer';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { AllUsersButton } from '../AllUsersButton';
import { FundDropdownBase, INVESTOR_VIEW_ROUTES } from './FundDropdownBase';
import { MultipleFundDropdown } from './MultipleFundDropdown';
import { DropDownOrderValues } from 'api/redux/types';
import { allowedMultipleDropdownView } from '../Helpers/utils';

const SingleFundDropdown: FC = () => {
	const dispatch = useDispatch();
	const grants = useSelector(selectDropdown);
	const { currentInvestors, currentFund, firstDropdown, currentFunds } =
		grants.grants;

	const shouldShowPlaceholder =
		firstDropdown === DropDownOrderValues.INVESTOR &&
		currentInvestors.length === 0;

	const handleFundChange = useCallback(
		(event: SelectChangeEvent<unknown>) => {
			const selectedFundId = Number(event.target.value);
			const selectedFund = currentFunds.find(
				(fund) => fund.id === selectedFundId,
			);
			if (selectedFund) {
				dispatch(onChangeFund(selectedFund));
			}
		},
		[currentFunds, dispatch],
	);

	if (shouldShowPlaceholder) {
		return (
			<FundDropdownBase
				id="fund-selector"
				value=""
				onChange={handleFundChange}
				disabled={true}
			>
				<MenuItem disabled value="">
					Please select investor(s)
				</MenuItem>
			</FundDropdownBase>
		);
	}

	return (
		<FundDropdownBase
			id="fund-selector"
			value={currentFund?.id?.toString() || ''}
			onChange={handleFundChange}
			title={currentFund.name}
		>
			{currentFunds.map((fund) => (
				<MenuItem key={fund.id} value={fund.id.toString()}>
					{fund.name}
				</MenuItem>
			))}
		</FundDropdownBase>
	);
};

export const FundDropdown: FC = () => {
	const { pathname } = useLocation();
	const grants = useSelector(selectDropdown);

	const isInvestorViewPage = useMemo(
		() =>
			INVESTOR_VIEW_ROUTES.includes(
				pathname as typeof INVESTOR_VIEW_ROUTES[number],
			),
		[pathname],
	);

	if (isInvestorViewPage) {
		return <AllUsersButton />;
	}

	const isMultipleAllowed = allowedMultipleDropdownView(pathname);
	if (isMultipleAllowed) {
		return <MultipleFundDropdown />;
	}

	return <SingleFundDropdown />;
};
