import { emptyInvestor, GrantState, GrantUpdates } from 'api/redux/types';
import { IFundGrant, IInvestorGrant, ISponsorGrant } from '@services/userApi';
import { DropDownOrderValues } from 'api/redux/types';

export const sortByName = <T extends { name: string }>(
	collection: T[],
): T[] => {
	const sortedCollection = [...collection];
	return sortedCollection.sort((a, b) => (a.name < b.name ? -1 : 1));
};

export const getFirstItemOrEmpty = <T>(items: T[], emptyItem: T): T => {
	return items.length ? items[0] : emptyItem;
};

// Funds

export const handleMultipleFundChange = (
	selectedFunds: IFundGrant[],
	grants: GrantState['grants'],
) => {
	if (selectedFunds.length === 0) {
		const allInvestors = getAllAvailableInvestors(grants.currentSponsor);
		return {
			currentFunds: [],
			availableInvestors: allInvestors,
			currentInvestors: [],
		};
	}

	const allInvestorsFromFunds = selectedFunds.reduce(
		(acc: IInvestorGrant[], fund) => {
			fund.investors?.forEach((investor) => {
				if (!acc.find((inv) => inv.id === investor.id)) {
					acc.push(investor);
				}
			});
			return acc;
		},
		[],
	);

	const sortedInvestors = sortByName(allInvestorsFromFunds);

	return {
		...grants,
		currentFunds: selectedFunds,
		availableInvestors: sortedInvestors,
		currentInvestors: [],
	};
};

export const handleFundOrderChange = (grants: GrantState['grants']) => {
	const availableFunds = getAvailableFundsForFundOrder(grants);
	const availableInvestors =
		grants.currentFunds.length > 0
			? handleMultipleFundChange(grants.currentFunds, grants).availableInvestors
			: getAvailableInvestorsForFundOrder(grants);

	return {
		availableFunds,
		currentInvestor: setCurrentInvestor(grants),
		availableInvestors,
		currentInvestors: [],
	};
};

// Investors
export const handleInvestorOrderChange = (grants: GrantState['grants']) => {
	const allAvailableInvestors = getAllAvailableInvestors(grants.currentSponsor);
	const sortedInvestors = sortByName(allAvailableInvestors);

	return {
		availableInvestors: sortedInvestors,
		availableFunds: grants.currentFunds, // Preserve current funds
		currentFund: grants.currentFund, // Preserve current fund
		currentInvestor: grants.currentInvestor,
		currentInvestors: grants.currentInvestors,
	};
};

export const getAllAvailableInvestors = (
	sponsor: ISponsorGrant,
): IInvestorGrant[] => {
	const investors = sponsor.funds.reduce((acc: IInvestorGrant[], fund) => {
		fund.investors?.forEach((investor) => {
			if (!acc.find((inv) => inv.id === investor.id)) {
				acc.push(investor);
			}
		});
		return acc;
	}, []);

	return sortByName(investors);
};

export const getInitialSponsorState = (
	selectedSponsor: ISponsorGrant,
	firstDropdown: DropDownOrderValues,
) => {
	const allAvailableInvestors = getAllAvailableInvestors(selectedSponsor);
	const sortedFunds = sortByName(selectedSponsor.funds);
	const [firstFund] = selectedSponsor.funds;
	const [firstInvestor] = firstFund.investors;

	if (firstDropdown === DropDownOrderValues.FUND) {
		return {
			currentSponsor: selectedSponsor,
			currentFund: firstFund,
			currentFunds: [firstFund],
			currentInvestor: firstInvestor,
			currentInvestors: [firstInvestor],
			allAvailableInvestors: sortByName(allAvailableInvestors),
			availableFunds: sortedFunds,
			availableInvestors: sortByName(firstFund.investors),
		};
	}

	return {
		currentSponsor: selectedSponsor,
		currentFund: firstFund,
		currentFunds: [firstFund],
		currentInvestor: firstInvestor,
		currentInvestors: [firstInvestor],
		allAvailableInvestors: sortByName(allAvailableInvestors),
		availableFunds: [],
		availableInvestors: sortByName(allAvailableInvestors),
	};
};

export const getAvailableFundsForFundOrder = (grants: GrantState['grants']) => {
	return sortByName(grants.currentSponsor.funds);
};

export const getAvailableInvestorsForFundOrder = (
	grants: GrantState['grants'],
) => {
	return sortByName(grants.currentFund.investors);
};

export const setCurrentInvestor = (grants: GrantState['grants']) => {
	const currentInvestor = grants.currentFund.investors.find(
		(investor) => investor.id === grants.currentInvestor.id,
	);
	return (
		currentInvestor ||
		getFirstItemOrEmpty(grants.currentFund.investors, grants.currentInvestor)
	);
};

export const getAvailableFundsForInvestorOrder = (
	currentSponsor: ISponsorGrant,
	currentInvestorId: number,
): IFundGrant[] => {
	const investorFunds = currentSponsor.funds.filter((fund) =>
		fund.investors.some((investor) => investor.id === currentInvestorId),
	);
	return sortByName(investorFunds);
};

export const setCurrentFund = (
	currentFundId: number,
	availableFunds: IFundGrant[],
	defaultFund: IFundGrant,
): IFundGrant => {
	const currentFund = availableFunds.find((fund) => fund.id === currentFundId);
	return currentFund || getFirstItemOrEmpty(availableFunds, defaultFund);
};

export const handleMultipleInvestorChange = (
	selectedInvestors: IInvestorGrant[],
	grants: GrantState['grants'],
): GrantUpdates => {
	const allFunds = sortByName(grants.currentSponsor.funds);

	if (selectedInvestors.length === 0) {
		return {
			currentInvestors: [],
			currentInvestor: emptyInvestor,
			availableFunds: allFunds,
			currentFunds:
				grants.firstDropdown === DropDownOrderValues.FUND
					? grants.currentFunds
					: [],
		};
	}

	return {
		currentInvestors: selectedInvestors,
		currentInvestor: selectedInvestors[0] || emptyInvestor,
		availableFunds:
			grants.firstDropdown === DropDownOrderValues.FUND
				? allFunds
				: grants.availableFunds,
		currentFunds:
			grants.firstDropdown === DropDownOrderValues.FUND
				? grants.currentFunds
				: grants.availableFunds,
	};
};
