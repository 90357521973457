import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './store';
import {
	GrantState,
	DropDownOrderValues,
	emptyFund,
	emptyInvestor,
	emptySponsor,
} from './types';
import { ISponsorGrant, IFundGrant, IInvestorGrant } from './services/userApi';
import {
	handleFundOrderChange,
	handleInvestorOrderChange,
	getInitialSponsorState,
	sortByName,
	handleMultipleFundChange,
} from './helpers/DropdownReducerHelpers';
import {
	handleUserGrantsChange,
	handleShowAllUsers,
	handleFundChange,
	handleInvestorChange,
} from './helpers/StateHelpers';

const initialState: GrantState = {
	grants: {
		currentSponsor: emptySponsor,
		availableSponsors: [],
		currentFund: emptyFund,
		currentFunds: [],
		availableFunds: [],
		currentInvestor: emptyInvestor,
		currentInvestors: [],
		availableInvestors: [],
		allAvailableInvestors: [],
		firstDropdown: DropDownOrderValues.FUND,
	},
	showAllUsers: false,
};

export const grantSlice = createSlice({
	name: 'grants',
	initialState,
	reducers: {
		onChangeSponsor: (state, action: PayloadAction<ISponsorGrant>) => {
			const initialState = getInitialSponsorState(
				action.payload,
				state.grants.firstDropdown,
			);

			Object.assign(state.grants, initialState);
		},

		onChangeFund: (state, action: PayloadAction<IFundGrant>) => {
			const updates = handleFundChange({
				selectedFund: action.payload,
				currentState: state.grants,
				emptyInvestor,
			});
			Object.assign(state.grants, updates);
		},

		onChangeInvestor: (state, action: PayloadAction<IInvestorGrant>) => {
			const updates = handleInvestorChange({
				selectedInvestor: action.payload,
				currentState: state.grants,
				emptyFund,
			});
			Object.assign(state.grants, updates);
		},

		onChangeFunds: (state, action: PayloadAction<IFundGrant[]>) => {
			const updates = handleMultipleFundChange(action.payload, state.grants);
			Object.assign(state.grants, updates);
		},

		onChangeInvestors: (state, action: PayloadAction<IInvestorGrant[]>) => {
			if (action.payload.length === 0) {
				state.grants.currentInvestors = [];
				if (state.grants.firstDropdown !== DropDownOrderValues.FUND) {
					state.grants.currentInvestors = [state.grants.availableInvestors[0]];
					state.grants.currentFunds = [];
					state.grants.availableFunds = sortByName(
						state.grants.currentSponsor.funds,
					);
				}
				return;
			}

			state.grants.currentInvestors = action.payload;
			state.grants.currentInvestor = action.payload[0];

			if (state.grants.firstDropdown === DropDownOrderValues.INVESTOR) {
				const filteredFunds = state.grants.currentSponsor.funds.filter((fund) =>
					action.payload.some((selectedInvestor) =>
						fund.investors.some(
							(fundInvestor) => fundInvestor.id === selectedInvestor.id,
						),
					),
				);
				state.grants.currentFunds = filteredFunds;
				state.grants.availableFunds = filteredFunds;
			}
		},

		onChangeUserGrants: (
			state,
			action: PayloadAction<{
				userGrants: ISponsorGrant[];
				loadCookies: boolean;
			}>,
		) => {
			const { userGrants, loadCookies } = action.payload;
			if (!loadCookies) {
				state.grants.availableSponsors = sortByName(userGrants);
				return;
			}

			const updates = handleUserGrantsChange({
				userGrants,
				loadCookies,
				emptyInvestor,
			});
			Object.assign(state.grants, updates);
		},

		onShowAllUsers: (state, action: PayloadAction<boolean>) => {
			const updates = handleShowAllUsers({
				showAll: action.payload,
				currentState: state.grants,
			});
			Object.assign(state.grants, updates);
			state.showAllUsers = action.payload;
		},

		onChangeDropdownOrder: (
			state,
			action: PayloadAction<DropDownOrderValues>,
		) => {
			const newOrder = action.payload;
			const updates = {
				...state.grants,
				...(newOrder === DropDownOrderValues.FUND
					? handleFundOrderChange(state.grants)
					: handleInvestorOrderChange(state.grants)),
				firstDropdown: newOrder,
			};
			Object.assign(state.grants, updates);
		},
	},
});

export const {
	onChangeSponsor,
	onChangeFund,
	onChangeInvestor,
	onChangeFunds,
	onChangeInvestors,
	onChangeUserGrants,
	onShowAllUsers,
	onChangeDropdownOrder,
} = grantSlice.actions;

export const selectDropdown = ({ grants }: RootState): GrantState => grants;

export default grantSlice.reducer;
